import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; // Asegúrate de haber instalado react-router-dom
// import { useCustomNavigate } from '../utils/customNavigation';

function NavigationBar() {
  const location = useLocation(); // Obtiene la ubicación actual
  // const customNavigate = useCustomNavigate();

  // Función para verificar si el enlace es la página actual
  const checkActive = (path) => location.pathname === path;
  const checkActivePrefix = (path) => location.pathname.includes(path);

  return (
    <Navbar collapseOnSelect expand="md" bg="light" sticky="top" className='text-poppins g-0'>
      <Navbar.Brand href="/">
        <img src="../../images/logo.svg" alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" className='me-3'/>
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between text-center">
        <Nav className="justify-content-center text-center mx-auto nav-custom-responsive gap-3 pb-5 px-2" >
          <Nav.Link href="/" className={checkActive('/') ? 'rounded-pill bg-black text-white ' : ''}>Home</Nav.Link>
          <Nav.Link href="/documents" className={checkActive('/documents') ? 'rounded-pill bg-black text-white mx-2' : ''}>Documentos</Nav.Link>
          <Nav.Link href="/faqs" className={checkActive('/faqs') ? 'rounded-pill bg-black text-white' : ''}>FAQs</Nav.Link>
          <Nav.Link href="/terms" className={checkActive('/terms') ? 'rounded-pill bg-black text-white' : ''}>Terms</Nav.Link>
          <Nav.Link href='/blog' className={checkActivePrefix('/blog') ? 'rounded-pill bg-black text-white' : ''}>Blog</Nav.Link>
        </Nav>
        <Button variant="outline-success" className='custom-button me-md-3 ' href='/cotizar'>
         Cotizar
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
