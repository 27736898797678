import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import propTypes from 'prop-types';
import * as motion from "motion/react-client"

const BlogCarousel = ({ posts }) => {
    const navigate = useNavigate();
    const handlePostClick = useCallback((postId) => navigate(`/blog/post/${postId}`), [navigate]);

    return (
        <div
            className={`align-content-center w-100 mx-lg-0 px-lg-0 px-lg-5 my-1 my-lg-0 d-flex gap-3 gap-lg-2 justify-content-center`}
        >
            {posts.map(({ id, title, cardImgPath, resume }) => (
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} key={id} className={`d-flex justify-align-content-between pl-1 ${resume ? ' blog-card' : ' blog-card-no-resume'}`} onClick={() => handlePostClick(id)}>
                    <div className='px-lg-4 d-flex justify-content-center align-items-end pb-1'>
                        <img src={`${window.location.origin}/${cardImgPath.path}`} alt={cardImgPath.alt} className='blog-card-img'/>
                    </div>
                    <div className='d-flex m-l-auto flex-column blog-card-body w-full  '>
                        <h1 className='blog-card-title'>{title}</h1>
                        <p className='blog-card-text'>{resume}</p>
                    </div>
                </motion.div>
            ))}
        </div>
    );
};

export default BlogCarousel;

BlogCarousel.propTypes = {
    // size: propTypes.oneOf(['small', 'medium', 'large']).isRequired,
    // justify: propTypes.oneOf(['start', 'center', 'end']).isRequired,
    posts: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number.isRequired,
            title: propTypes.string.isRequired,
            resume: propTypes.string,
            cardImgPath: propTypes.shape({
                path: propTypes.string.isRequired,
                alt: propTypes.string.isRequired
            }).isRequired
        }),
    ).isRequired
}
