const logos = {
    logo_nomanejes: { src: "/images/banner/logo_nomanejes.svg", alt: "Logo Nomanejes" },
    logo_puerto_rico_car_transfer: { src: "/images/banner/logo_puertoricocartransfer.svg", alt: "Logo Puerto Rico Car Transfer" },
    logo_carro_a_centro_america: { src: "/images/banner/logo_carrosacentroamerica.svg", alt: "Logo Carro a Centro America" },
    logo_pr_autotransport: { src: "/images/banner/logo_prautotransport.svg", alt: "Logo PR Autotransport" },
    logo_carmoversus: { src: "/images/banner/logo_carmoversus.svg", alt: "Logo Carmoversus" },
    logo_shipshippersusa: { src: "/images/banner/logo_shipshippersusa.svg", alt: "Logo Shipshippers USA" },
    logo_ratesautotransport: { src: "/images/banner/logo_ratesautotransport.svg", alt: "Logo Rates Autotransport" },
    logo_tucarroporusa: { src: "/images/banner/logo_tucarroporusa.svg", alt: "Logo Tu Carro Por Usa" },
    logo_tcpamerica: { src: "/images/banner/logo_tcpamerica.svg", alt: "Logo TCP America" },
}

const logos_ally = {
    logo_ally_centraldispatch: { src: "/images/banner/logo_ally_centraldispatch.svg", alt: "Logo Ally Central Dispatch" },
    logo_ally_seaboard: { src: "/images/banner/logo_ally_seaboardmarine.svg", alt: "Logo Ally Seaboard" },
    logo_ally_trailerbridge: { src: "/images/banner/logo_ally_trailerbridge.svg", alt: "Logo Ally Trailer Bridge" },
    logo_crowley: { src: "/images/banner/logo_ally_crowley.svg", alt: "Logo Crowley" },
    logo_interport: { src: "/images/banner/logo_ally_interport.svg", alt: "Logo Interport" },
    logo_cmacgm: { src: "/images/banner/logo_ally_cmacgm.svg", alt: "Logo CMACGM" },
}

const logos_payments = {
    logo_visa: { src: "/images/banner/logo_payment_visa.svg", alt: "Logo Visa" },
    logo_mastercard: { src: "/images/banner/logo_payment_mastercard.svg", alt: "Logo Mastercard" },
    logo_americanexpress: { src: "/images/banner/logo_payment_americanexpress.svg", alt: "Logo Paypal" },
}

export default function BannerLogos() {
    return (
        <div className="d-flex flex-column w-100 gap-3">
            <div className="d-flex justify-content-between w-100">
                <img src={logos.logo_nomanejes.src} alt={logos.logo_nomanejes.alt} style={{width: '18%'}} />
                <img src={logos.logo_puerto_rico_car_transfer.src} alt={logos.logo_puerto_rico_car_transfer.alt} style={{ width: '18%' }} />
                <img src={logos.logo_carro_a_centro_america.src} alt={logos.logo_carro_a_centro_america.alt} style={{ width: '14%' }} />
                <img src={logos.logo_pr_autotransport.src} alt={logos.logo_pr_autotransport.alt} style={{ width: '12%' }} />
                <img src={logos.logo_carmoversus.src} alt={logos.logo_carmoversus.alt} style={{ width: '10%' }} />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 ">
                <img src={logos.logo_shipshippersusa.src} alt={logos.logo_shipshippersusa.alt} style={{ width: '34%' }} />
                <img src={logos.logo_ratesautotransport.src} alt={logos.logo_ratesautotransport.alt} style={{ width: '20%' }} />
                <img src={logos.logo_tucarroporusa.src} alt={logos.logo_tucarroporusa.alt} style={{ width: '12%' }} />
                <img src={logos.logo_tcpamerica.src} alt={logos.logo_tcpamerica.alt} style={{ width: '22%' }} />
            </div>

            <div className="d-flex justify-content-between w-100 ">
                <img src={logos_ally.logo_ally_centraldispatch.src} width={"26%"} alt={logos_ally.logo_ally_centraldispatch.alt} />
                <img src={logos_ally.logo_ally_seaboard.src} width={"14%"} alt={logos_ally.logo_ally_seaboard.alt} />
                <img src={logos_ally.logo_ally_trailerbridge.src} width={"18%"} alt={logos_ally.logo_ally_trailerbridge.alt} />
                <img src={logos_ally.logo_crowley.src} width={"16%"} alt={logos_ally.logo_crowley.alt} />
                <img src={logos_ally.logo_interport.src} width={"14%"} alt={logos_ally.logo_interport.alt} />
            </div>

            <div className="d-flex justify-content-between align-items-center w-100">
                <img src={logos_ally.logo_cmacgm.src} width={"100px"} alt={logos_ally.logo_cmacgm.alt} />
            </div>

            <div className="d-flex flex-column justify-content-between align-items-center gap-2 w-100">
                <div className="d-flex gap-2 justify-content-center gap-1">
                    <img src={logos_payments.logo_visa.src} height={"18px"} alt={logos_payments.logo_visa.alt} />
                    <img src={logos_payments.logo_mastercard.src} height={"26px"} alt={logos_payments.logo_mastercard.alt} />
                    <img src={logos_payments.logo_americanexpress.src} height={"24px"} alt={logos_payments.logo_americanexpress.alt} />
                </div>
                <p className="text-center text-font" style={{ fontSize: "20px", fontWeight: 500, lineHeight: "24px" }}>Comunícate con alguna de <br/> nuestras empresas HOY.</p>
            </div>

        </div>
    )
}