import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';





function Somos() {
    return (
      <div id='somos'>
        <Row className="align-items-center text-poppins g-0 mb-5 my-5">
          <Col md={6}>
            <p style={{fontSize: '2rem', lineHeight: '1.3'}} className='px-5 mt-5'>Somos especialistas en envío de vehículos entre <span className='fw-bold'>Puerto Rico</span> y <span className='fw-bold'>USA</span>. Simplificamos tu traslado con seguridad y confianza.</p>
          </Col>
          <Col md={6} className="d-flex justify-content-center mt-3">
          <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="container1 mb-5 d-flex justify-content-evenly">
            <Image src="/images/somos1.png" className="responsive-image1 rounded fluid" />
            <Image src="/images/somos2.png" className="responsive-image1 rounded fluid mx-3"  />
            <Image src="/images/somos3.png" className="responsive-image1 rounded fluid" />
          </div>
              <a className='btn text-white button-somos g-0 mb-3' href='/cotizar'>Cotiza tu envio</a>
            </div>
          </Col>
        </Row>

      </div>
    );
  }
  
  export default Somos;
  