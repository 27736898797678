import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const QuoteHome = () => {
    const navigate = useNavigate();

    const envioDesdeRef = useRef(null);
    const hastaRef = useRef(null);

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 2; // Now only 2 steps

    const [formData, setFormData] = useState({
        vehiculo: '',
        envioDesde: '',
        hasta: '',
        nombreApellido: '',
        email: '',
        telefono: '',
    });

    const [errors, setErrors] = useState({
        vehiculo: true,
        envioDesde: true,
        hasta: true,
        nombreApellido: true,
        email: true,
        telefono: true,
    });

    const [touched, setTouched] = useState({
        vehiculo: false,
        envioDesde: false,
        hasta: false,
        nombreApellido: false,
        email: false,
        telefono: false,
    });

    useEffect(() => {
        if (!window.google) return;

        initAutocomplete(envioDesdeRef.current, 'envioDesde');
        initAutocomplete(hastaRef.current, 'hasta');
    }, []);

    const initAutocomplete = (element, fieldName) => {
        if (!element) return;

        const autocomplete = new window.google.maps.places.Autocomplete(element, { types: ['geocode'], componentRestrictions: { country: ['us', 'pr', 'gt', 'sv', 'ni', 'hn'] } });
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                setErrors(prev => ({ ...prev, [fieldName]: true }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            } else {
                setFormData(prev => ({ ...prev, [fieldName]: place.formatted_address }));
                setErrors(prev => ({ ...prev, [fieldName]: false }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            }
        });
    };

    const validateField = (name, value) => {
        const phoneRegex = /^\(\d{3}\)-\d{3}-\d{4}-?\d{0,4}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        switch (name) {
            case 'vehiculo':
                return value.trim() !== '';
            case 'nombreApellido':
                return value.trim() !== '';
            case 'telefono':
                return phoneRegex.test(value);
            case 'email':
                return emailRegex.test(value);
            default:
                return false;
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
    let isValid = true;

    if (name === "telefono") {
        // Elimina todos los caracteres que no sean dígitos
        
        const digits = value.replace(/\D/g, "");

        // Limita la cantidad de dígitos a 15, permitiendo hasta 4 caracteres adicionales por los guiones
        const limitedDigits = digits.slice(0, 15);

        let formattedNumber = '';

        // Formatea el número con paréntesis y guiones
        if (limitedDigits.length <= 3) {
            formattedNumber = `(${limitedDigits}`;
        } else if (limitedDigits.length <= 6) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3)}`;
        } else if (limitedDigits.length <= 10) {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
        } else {
            formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6, 10)}-${limitedDigits.slice(10)}`;
        }
        // Limita la longitud total del campo a 16 caracteres (paréntesis, guiones y dígitos)
        formattedNumber = formattedNumber.substring(0, 19);

        isValid = validateField(name, formattedNumber);
        setFormData(prevData => ({ ...prevData, [name]: formattedNumber }));
    } else {
        isValid = validateField(name, value);
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }

    setTouched(prev => ({ ...prev, [name]: true }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: !isValid }));
    };
    
    const nextStep = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const isCurrentStepValid = () => {
        switch (currentStep) {
            case 1:
                return !errors.envioDesde && !errors.hasta && !errors.vehiculo;
            case 2:
                return !errors.nombreApellido && !errors.email && !errors.telefono;
            default:
                return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { vehiculo, envioDesde, hasta, nombreApellido, email, telefono } = formData;
        try {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fields: {
                        TITLE: nombreApellido,
                        EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                        PHONE: [{ VALUE: telefono, VALUE_TYPE: 'WORK' }],
                        UF_CRM_1470686551: envioDesde,
                        UF_CRM_1470686597: hasta,
                        SOURCE_ID: "Solicitud de cotización Prautotransport",
                        UF_CRM_1486142337: vehiculo,
                        "ASSIGNED_BY_ID": "2"
                    }
                }),
            };

            const response = await fetch('https://carmoversus.com/api/v1/lead', options);
            console.log(response)
            if (!response.ok) throw new Error('Network response was not ok');
            navigate('/gracias');

            resetFormStates();
        } catch (error) {
            console.error("There was an error adding the lead:", error);
        }
    };

    const resetFormStates = () => {
        setFormData({
            vehiculo: '',
            envioDesde: '',
            hasta: '',
            nombreApellido: '',
            email: '',
            telefono: '',
        });
        setErrors({
            vehiculo: true,
            envioDesde: true,
            hasta: true,
            nombreApellido: true,
            email: true,
            telefono: true,
        });
        setTouched({
            vehiculo: false,
            envioDesde: false,
            hasta: false,
            nombreApellido: false,
            email: false,
            telefono: false,
        });
    };


    return (
<Card className="px-5 my-3 rounded-4">
    <form action="#" method="post" onSubmit={handleSubmit} className='my-5'>
        {currentStep === 1 && (
            <div className="d-md-flex justify-content-between align-align-items-baseline ">
                {/* Step 1: Envio Desde, Hasta, Vehiculo */}
                <div className="form-group flex-fill me-2">
                    <label className='mb-2 fw-medium' htmlFor='vehiculo'>Marca, Modelo y Año</label>
                    <input
                        className={`form-control rounded-pill ${errors.vehiculo && touched.vehiculo ? 'is-invalid' : ''}`}
                        type="text"
                        name="vehiculo"
                        onChange={handleChange}
                    />
                    {errors.vehiculo && touched.vehiculo && <div className="invalid-feedback">Este campo es obligatorio.</div>}
                </div>

                <div className="form-group flex-fill me-2">
                    <label className='mb-2 fw-medium' htmlFor='envioDesde'>Origen</label>
                    <input
                        className={`form-control rounded-pill ${errors.envioDesde && touched.envioDesde ? 'is-invalid' : ''}`}
                        type="text"
                        ref={envioDesdeRef}
                        onFocus={() => setTouched({ ...touched, envioDesde: true })}
                    />
                        {errors.envioDesde && touched.envioDesde && <div className="invalid-feedback">Por favor, seleccione un lugar válido.</div>}
                </div>

                <div className="form-group flex-fill me-2">
                    <label className='mb-2 fw-medium' htmlFor='hasta'>Destino</label>
                    <input
                        className={`form-control rounded-pill ${errors.hasta && touched.hasta ? 'is-invalid' : ''}`}
                        type="text"
                        ref={hastaRef}
                        onFocus={() => setTouched({ ...touched, hasta: true })}
                    />
                    {errors.hasta && touched.hasta && <div className="invalid-feedback">Por favor, seleccione un lugar válido.</div>}
                </div>



                {currentStep < totalSteps && (
                <div className="mt-4">
                    <button type="button" className='btn btn-continue ms-md-3 w-100 mt-2 rounded-pill px-5' onClick={nextStep} disabled={!isCurrentStepValid()}>
                        Continue
                    </button>
                </div>
                )}
            </div>
        )}

        {currentStep === 2 && (
            <div className="d-md-flex justify-content-between align-align-items-baseline">
            {/* Step 2: Nombre y Apellido, Email, Teléfono */}
                <div className="form-group flex-fill me-2">
                    <label className='mb-2 fw-medium' htmlFor='nombreApellido'>Nombre y apellido</label>
                    <input
                        className={`form-control rounded-pill ${errors.nombreApellido && touched.nombreApellido ? 'is-invalid' : ''}`}
                        type="text"
                        name="nombreApellido"
                        onChange={handleChange}
                        onBlur={() => setTouched({ ...touched, nombreApellido: true })}
                    />
                    {errors.nombreApellido && touched.nombreApellido && <div className="invalid-feedback">Este campo es obligatorio.</div>}
                </div>

                <div className="form-group flex-fill me-2">
                    <label className='mb-2 fw-medium' htmlFor='email'>Email</label>
                    <input
                        className={`form-control rounded-pill ${errors.email && touched.email ? 'is-invalid' : ''}`}
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={() => setTouched({ ...touched, email: true })}
                    />
                    {errors.email && touched.email && <div className="invalid-feedback">Por favor, introduzca un email válido.</div>}
                </div>

                <div className="form-group flex-fill">
                    <label className='mb-2 fw-medium' htmlFor='telefono'>Teléfono</label>
                    <input
                        className={`form-control rounded-pill ${errors.telefono && touched.telefono ? 'is-invalid' : ''}`}
                        type="tel"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleChange}
                        onBlur={() => setTouched({ ...touched, telefono: true })}
                    />
                    {errors.telefono && touched.telefono && <div className="invalid-feedback">Por favor, introduzca un teléfono válido.</div>}
                </div>

                <div>

                    {currentStep === totalSteps && (
                    <div className="mt-4">
                        <button type="submit" className='btn btn-continue ms-md-3 w-100 mt-2 rounded-pill' disabled={!isCurrentStepValid()}>
                            Solicitar cotización
                        </button>
                    </div>
                    )}
                </div>
            </div>
        )}
    </form>
</Card>

    );
};

export default QuoteHome;
