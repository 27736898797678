import React from 'react';
import { Col, Row } from 'react-bootstrap';
import QuoteHome from '../components/QuoteHome';

function Index() {
    const backgroundStyle = {
        backgroundImage: 'url("/images/home.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '100vh',
        position: 'relative' // Make this a positioned container
    };

    const bottomContainerStyle = {
        position: 'absolute', // Absolutely position the child
        bottom: '10%',            // At the bottom of the parent
        width: '100%',        // Ensure it spans the full width
        paddingLeft: '10%',
        paddingRight: '10%'
    };

    return (
        <div style={backgroundStyle} className='g-0' id='index'>
            <Row className="text-poppins g-0">
                <Col md={1}></Col>
                <Col md={7} className="px-2" style={{ marginTop: '5rem' }}>
                    <h1 className='ms-5 mt-5 text-home d-none d-md-block px-4' style={{ fontSize: '3rem' }}>
                        Transportamos tu vehículo de manera <span className='fw-bold'>rápida</span> y <span className='fw-bold'>confiable</span>
                    </h1>
                    <div className='ms-3 d-none d-md-block'>
                        <a href='tel:+17877416032' className='btn btn-continue ms-md-5 py-3 w-50 mt-3 rounded-pill px-5 fs-5'>
                            Contactanos: +1 787-741-6032
                        </a>
                    </div>
                    <h1 className='ms-5 d-block d-md-none text-home px-1' style={{ fontSize: '2rem' }}>
                        Transportamos tu vehículo de manera <span className='fw-bold'>rápida</span> y <span className='fw-bold'>confiable</span>
                    </h1>
                    <div className='ms-3 d-block d-md-none px-5'>
                        <a href='tel:+17877416032' className='btn btn-continue ms-md-5 py-3 w-100 mt-5 rounded-pill px-5 fs-6'>
                            LLAMA YA: +1 787-741-6032
                        </a>
                    </div>
                </Col>
                <Col md={4}></Col>
            </Row>
            <div style={bottomContainerStyle} className='mx-auto' >
                <QuoteHome /> {/* This is now absolutely positioned at the bottom */}
            </div>
        </div>
    );
}

export default Index;
