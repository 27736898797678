import React from 'react';
import { Row} from 'react-bootstrap';

const Tiempos = () => {
  return (
    <div fluid style={{ backgroundColor: '#DF495A', color: '#FFFFFF' }} className='d-none d-md-block' id='tiempos'>
      <Row className="align-items-center text-center g-0">
        
        <div style={{ maxWidth: '70%', margin: '0 auto'}} className='d-flex align-items-center px-5 mt-5'>
          <img src='/images/mapausa.png' alt="Mapa de Estados Unidos" className="img-fluid "
           style={{
            maxWidth: '100%', // O cualquier otro tamaño que prefieras
            height: 'auto', // Esto asegura que la imagen mantenga su aspecto original
          }}
     />
     </div>
      </Row>
    </div>
  );
};

export default Tiempos;