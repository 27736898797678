import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import QuoteCotizar from '../components/QuoteCotizar';
function Cotizar() {
    return (
        <div className="">
            <Row className='text-poppins g-0 ' id='cotizar'>
                <Col md={6} className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#DF495A' }}>
                    <Image src="/images/cotizar.png" alt="Descripción de la imagen" className="img-fluid" style={{ maxHeight: '75%' }} />
                </Col>
                <Col md={6} className="d-flex flex-column justify-content-center ">
                    <div className='mx-auto w-50 text-center'>

                        <h1 className="mb-3 fw-bold mt-5">Envía tu auto con PRautotransport</h1>
                    </div>
                    <QuoteCotizar></QuoteCotizar>
                </Col>
            </Row>
        </div>
    );
}

export default Cotizar;
