import React, { useState } from 'react';
import { Row, Col, Accordion, Image } from 'react-bootstrap';
import '../step.css'

function Faqs() {


    const [activeKey, setActiveKey] = useState("0");

  return (
    <div id='faqs'>
      <Row className='g-0 my-3 text-poppins'>
        {/* Columna izquierda: Título, texto e imagen */}
        <Col md={6} className='justify-content-center mt-5'>
          <h2 className='fw-bold px-5 mt-3  justify-content-center' style={{fontSize: '2.5rem'}}>Preguntas frecuentes.<br></br>Todo lo que necesitas saber</h2>
          <p className='px-5 mt-3'>En nuestra sección de Preguntas Frecuentes, hemos recopilado las respuestas más completas y claras para las dudas que puedas tener sobre nuestros servicios de envío de vehículos.</p>
          <Image src="/images/faqs.png" alt="Descripción de la imagen" fluid />
        </Col>

        {/* Columna derecha: Acordeón con preguntas frecuentes */}
        <Col md={6}>
        <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75" >
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>¿Qué formas de pago aceptan?</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#D1DEEB"}} >
              Aceptamos Visa, Master Card, American Express, Discover, transferencias electronicas, giro bancarios y cheques de empresa. El pago debe hacerse en su totalidad antes de la fecha de su envio
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>¿Hay restricciones en el tamaño del vehículo?</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#D1DEEB"}}>
              Si. Se aplican las tarifas en linea para vehiculos de tamaño estandar. Un vehiculo de tamaño estandar se define como cualquier automovil de pasajeros que no exceda 21'6¨ de largo x 7' alto. <br></br><br></br>Si su vehiculo es mas grande que las dimensiones estandar, pongase en contactocon nuestro departamento de envio de gran tamaño llamando al 1(787)741-6032 para recibir una cotizacion personalizada para su transporte.

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>¿Qué se requiere en la fecha de envío?</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#D1DEEB"}}>
              Por favor revise nuestra lista de documentos en el menu superior de esta pagina, para ver lo que se requiere cuando envia un vehiculo desde o hacia Puerto Rico.Si su vehiculo es mas grande que las dimensiones estandar, pongase en contacto con nuestro departamento de envio de gran tamaño llamando al 1(787)741-6032 para recibir una cotizacion personalizada para su transporte.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>¿Cómo puedo reservar mi envío?</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#D1DEEB"}}>
              Para su conveniencia, puede reservar su envio de forma segura en linea llenando el formulario de cotizacion y un coordinador de envio se comunicara a la brevedad. Tambien puede llamarnos al (787)741-6032.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>¿Puedo tener algo dentro de mi vehículo?</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#D1DEEB"}}>
              Su vehiculo debe estar limpio y vacio. Se hacen excepciones para asientos infantiles, cables de puente y algunas piezas de automovil.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>¿Cuánta gasolina puedo tener en mi vehículo?</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#D1DEEB"}}>
              Segun el reglamento de la Guardia Costera, el vehiculo debe tener menos de 1/4 del tanque, sin excepciones.tenga en cuenta que no ofrecemos servicios de extraccion de combustible
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0" className="custom-accordion mt-3 mx-5 w-75">
            <Accordion.Item eventKey="99">
              <Accordion.Header onClick={() => setActiveKey(activeKey === "0" ? "" : "0")}>
              <div style={{ flex: 1 }} className='fw-bold'>¿Que más envía a Puerto Rico aparte de vehículos?</div>
                <img src={activeKey === "0" ? '/images/stepbuttom.png' : '/images/stepbuttom2.png'} alt="Toggle" className="accordion-toggle-icon" />
              </Accordion.Header>
              <Accordion.Body style={{background: "#D1DEEB"}}>
            
Aparte de automoviles de pasajeros, podemos enviar motos,botes, vehiculos recreativos, equipo pesado y cualquier otro envio de gran tamaño a Puerto Rico. Si desea hablar con un coordinador de envio con respecto a su transporte, porfavor llamar al (787) 741-6032
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>





        </Col>
      </Row>
    </div>
  );
}

export default Faqs;
