import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Payment = () => {
  const paymentMethods = [
    '/images/paypal.png', // Asegúrate de que estas rutas sean correctas
    '/images/visa.png',
    '/images/zelle.png',
    '/images/master.png',
  ];

  return (
    <Container className="my-5 mx-auto " id='payment'>
      <Row className="justify-content-center payment-row">
        <Col md={12}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundColor: '#4C7FAD',
            borderRadius: '25px', // Ajusta esto para el redondeo que prefieras
            padding: '15px',
            margin: '30x', // Añade margen si necesitas más espacio alrededor del rectángulo
          }}>
            {paymentMethods.map((src, index) => (
              <Image className='payment-container' key={index} src={src} alt={`Payment method ${index + 1}`} style={{ maxWidth: '150px', }} />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Payment;
