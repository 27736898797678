import React from 'react';
import { Carousel, Image,} from 'react-bootstrap';

function InfoMovil() {
    const carouselItemStyle = {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '15px',
    };
    
    const carouselImageStyle = {
        width: '100%',
        height: 'auto',
        borderRadius: '5px',
    };
    
    const textStyle = {
        paddingTop: '10px',
    };
    
    const carouselStyle = {
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%', // Se ajusta para dar espacio a las imágenes adyacentes
        overflow: 'hidden' // Asegura que no se muestre el contenido fuera de este contenedor
    };
;

  return (
    <div className='d-md-none g-0' style={{ backgroundColor: '#CF2437', padding: '20px', color: 'white', borderRadius: '20px' }} id='infomovil'>
     <div className='p-1 mt-5 mb-4 g-0'>
          <h1 className='fw-bold '>¿Por qué PRAutotransport?</h1>
          <p className=''>Descubre por qué somos la opción preferida para tus necesidades de transporte y gestión vehicular.</p>
        </div>
     
      <Carousel interval={4000} indicators={true} pause={false} style={carouselStyle} className='g-0 '>
        <Carousel.Item style={carouselItemStyle}>
          <Image
            src="/images/info1.png"
            alt="Servicio Integral"
            style={carouselImageStyle}
          />
          <div style={textStyle}>
          <h4 className='text-black fw-bold text-center'>Servicio Integral</h4>
              <p className='text-black'>Oficinas en Puerto Rico y USA. Brindamos servicios de Affidavit, lavado de autos, gestorias y servicio de puerta a puerta. </p>
            </div>
        </Carousel.Item>
       
        <Carousel.Item style={carouselItemStyle}>
          <Image
            src="/images/info2.png"
            alt="Servicio Integral"
            style={carouselImageStyle}
          />
          <div style={textStyle}>
         <h4 className='text-black fw-bold text-center'>Asistencia documental</h4>
              <p className='text-black'>Realiza con nosotros los documentos para el envío de tu carro con seguridad y tranquilidad.</p>
             </div>
        </Carousel.Item>

        <Carousel.Item style={carouselItemStyle}>
          <Image
            src="/images/info3.png"
            alt="Servicio Integral"
            style={carouselImageStyle}
          />
          <div style={textStyle}>
         <h4 className='text-black fw-bold text-center'>Mejor precio garantizado</h4>
              <p className='text-black'>Cotiza el envio de tu carro sin compromiso, te garantizamos el mejor precio del mercado. Si tienes un precio mas bajo lo igualamos.</p>
             </div>
        </Carousel.Item>


        <Carousel.Item style={carouselItemStyle}>
          <Image
            src="/images/info4.png"
            alt="Servicio Integral"
            style={carouselImageStyle}
          />
          <div style={textStyle}>
         <h4 className='text-black fw-bold text-center'>Servicio personalizado</h4>
              <p className='text-black'>Brindamos un servicio completamente personalizado, un asesor te acompañara 24/7 hasta que recibas tu carro en el destino final.</p>
             </div>
        </Carousel.Item>



        <Carousel.Item style={carouselItemStyle}>
          <Image
            src="/images/info5.png"
            alt="Servicio Integral"
            style={carouselImageStyle}
          />
          <div style={textStyle}>
         <h4 className='text-black fw-bold text-center'>Rápido y seguro</h4>
              <p className='text-black'>Ofrecemos un servicio rápido con salidas programadas todas las semanas para garantizar la tranquilidad en tus envíos</p>
             </div>
        </Carousel.Item>
       </Carousel>
    </div>
  );
}

export default InfoMovil;