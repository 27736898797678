import React from 'react';
import {Row, Col, Image, Carousel } from 'react-bootstrap';

const Review = () => {

    const reviews = [
      {
        image: './images/review.png',
        text: "Nunca pensé que enviar mi vehículo de Estados Unidos a Puerto Rico sería tan sencillo y seguro. La empresa de envío de vehículos hizo un trabajo excepcional desde el principio hasta el final. La comunicación fue clara, el proceso fue transparente y mi auto llegó en perfectas condiciones.",
        author: "José Rodríguez",
      },


      {
        image: './images/review2.png',
        text: "Mi experiencia con esta empresa de envío de vehículos fue simplemente impecable. Desde el primer contacto hasta la entrega final, todo fue profesional y eficiente. Mi auto llegó a Puerto Rico en perfectas condiciones y dentro del plazo prometido. ¡Recomiendo sus servicios sin dudarlo!",
        author: "Ana López",
      },


      {
        image: './images/review3.png',
        text: "Enviar mi vehículo desde Estados Unidos a Puerto Rico siempre me preocupaba, pero esta empresa cambió mi perspectiva por completo. La atención al cliente, la transparencia en el proceso, el acompañamiento y la seguridad en el transporte me brindaron confianza total. Gracias por hacerlo tan fácil.",
        author: "Camila Pérez",
      },
      // ...puedes agregar más reseñas aquí
    ];

    const fullStar = (
      <img src="/images/estrellas.svg" alt="Full Star" style={{ width: '200px', height: 'auto' }} />
    );


  return (
    <div id='review'>
      <Row className=" text-poppins g-0">
        {/* Texto a la izquierda */}
        <Col md={5} xs={12} className="mb-3  p-5">
          <h1 className='color-review ms-3'>Opiniones de nuestros clientes</h1>
          <p style={{fontSize:'1rem'}} className='mt-2 ms-3'>Descubre por qué nuestros clientes han confiado en nosotros y están satisfechos con nuestros servicios de envío de vehículos.</p>
        </Col>
        
        {/* Imagen y testimonio a la derecha */}
        <Col md={7} xs={12}>
        <Carousel indicators={true} interval={2000} variant="dark">
        {reviews.map((review, index) => (
        <Carousel.Item key={index} >
          <div className="testimonial-container p-5 m-5" style={{
            borderRadius: '10px',
            border: '3px solid black', // Línea negra para el borde
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            backgroundColor: '#fff', // Fondo blanco para el contenedor del testimonio
          }}>
            <Image src={review.image} alt="Imagen del cliente" style={{
              maxWidth: '250px',
              borderRadius: '10px',
              marginRight: '20px',
            }} />
            <div>
            <div className="my-3 text-center">
                                            {[...Array(1)].map((_, i) => fullStar)}
                                        </div>
              <p style={{ fontStyle: 'poppins' }}>"{review.text}"</p>
              <h3 className="fw-bold text-center">{review.author}</h3>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
        </Col>
      </Row>
    </div>
  );
};

export default Review;